@charset 'utf-8';
@import '_env.scss';

.frame {
	padding: 0 20px;
	margin: 0 auto;
	min-width: $maxwidth_content;
	@media (max-width: $breakpoint) {
		min-width: 0;
	}
}

.site-header {
	@media screen and (min-width: $breakpoint) {
		height: 110px;
	}

	.site-header-container {
		@extend %clearfix;
		position: relative;
		margin: 0 auto;
		padding: 43px 0 23px;
		width: $maxwidth_content;
		@media (max-width: $breakpoint) {
			width: auto;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			background: $color_ci;
			border-radius: 0 0 6px 6px;
			height: 6px;
		}
	}

	.title {
		float: left;

		a,
		img {
			display: block;
			margin: 0 auto;
			opacity: 1;
		}

		img {
			height: 44px;
		}
	}

	.navigation {
		.toggle {
			display: none;
		}
	}

	.menu {
		float: right;

		ul {
			float: right;
			margin: 0;
			padding: 0;
			list-style-type: none;
			font-weight: bold;

			> li {
				float: left;
				position: relative;
				margin: 0 0 0 12px;
				padding: 0 0 0 13px;

				&:before {
					content: "";
					position: absolute;
					left: 0;
					top: 10px;
					bottom: 10px;
					margin: auto;
					width: 1px;
					background: #d9d9d9;
				}

				&:first-child {
					margin-left: 0;
					padding-left: 0;
					border-left: 0 none;

					&:before {
						content: none;
					}
				}
			}

			a {
				display: inline-block;
				border-radius: 6px;
				padding: 0 14px;
				text-decoration: none;

				@media screen and (min-width: $breakpoint) {
					transition: all 0.16s ease-in-out;

					&:hover {
						background: $color_ci;
						color: $color_bg;
					}
				}

				&.current {
					background: $color_ci;
					color: $color_bg;
				}
			}
		}
	}

	.related-site {
		position: absolute;
		top: 7px;
		right: 0;

		ul {
			float: right;
			margin: 0;
			padding: 0;
			list-style-type: none;
			font-size: $fontsize_exsmall;
			line-height: $lineheight_normal;
			font-weight: bold;

			> li {
				float: left;
				position: relative;
				margin: 0 0 0 14px;
				padding: 0 0 0 15px;

				&:before {
					content: "";
					position: absolute;
					left: 0;
					top: 10px;
					bottom: 10px;
					margin: auto;
					width: 1px;
					background: #d9d9d9;
				}

				&:first-child {
					margin-left: 0;
					padding-left: 0;
					border-left: 0 none;

					&:before {
						content: none;
					}
				}
			}

			a {
				&[target="_blank"] {
					&:after {
						content: url(../img/icon_external.png);
						margin: 0 0 0 6px;
						vertical-align: middle;
					}
				}
			}
		}
	}

	@media screen and (min-width: $breakpoint) {
		&.scrolled {
			background: $color_bg;

			.site-header-container {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				z-index: 110;
				margin: auto;
				padding: 12px 15px;
				max-width: $maxwidth_content;
				@media (max-width: $breakpoint) {
					max-width: none;
				}
				height: 36px;
				animation: site-header-scrolled .4s ease-in-out;

				&:before {
					position: fixed;
					top: 0;
					left: 0;
					right: 0;
					z-index: -1;
					border-radius: 0;
					height: 60px;
					background: $color_bg;
					animation: site-header-scrolled .4s ease-in-out;
				}
			}

			.title {
				img {
					height: 36px;
				}
			}

			.related-site {
				display: none;
			}

			.menu {
				ul {
					a {
						position: relative;
						background: transparent;
						color: $color_text;

						&:before {
							content: "";
							position: absolute;
							top: -12px;
							left: 0;
							right: 0;
							border-radius: 0 0 6px 6px;
							margin: auto;
							width: 0%;
							height: 6px;
							background: $color_ci;
							transition: width .1s ease-in-out;
						}

						&.current {
							&:before {
								width: 100%;
							}
						}
					}
				}
			}
		}

		@keyframes site-header-scrolled {
			0% {
				top: -78px;
			}
			100% {
				top: 0px;
			}
		}
	}

	@media (max-width: $breakpoint) {
		.site-header-container {
			padding-top: 16px;
			padding-bottom: 14px;

			&:before {
				left: -5px;
				right: -5px;
				border-radius: 0 0 5px 5px;
				height: 5px;
			}
		}

		.title {
			float: none;

			a {
				display: table;
			}

			img {
				height: 28px;
			}
		}

		.navigation {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			z-index: 1000;
			margin: 0 0 0 -246px;
			width: 300px;
			overflow: hidden;
			pointer-events: none;

			.toggle {
				display: block;
				padding: 9px;
				pointer-events: auto;

				&:before {
					content: "";
					margin: 0 0 0 auto;
					display: block;
					width: 40px;
					height: 40px;
					background: $color_bg url(../img/icon_menu_closed.png) 50% 50% no-repeat;
				}

				span {
					display: none;
				}
			}

			.menu,
			.related-site {
				opacity: 0;
			}

			&.open,
			&.close {
				transition:
					margin-left 0.2s ease-in-out,
					background 0.2s ease-in-out,
					box-shadow 0.2s ease-in-out
				;

				.menu,
				.related-site {
					transition:
						opacity 0.2s ease-in-out
					;
				}
			}

			&.open {
				margin-left: 0;
				background: $color_bg;
				box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05);
				overflow: auto;
				pointer-events: auto;

				.toggle {
					&:before {
						background-image: url(../img/icon_menu_opened.png);
					}
				}

				.menu,
				.related-site {
					opacity: 1;
				}
			}
		}

		.menu {
			float: none;

			ul {
				float: none;
				border-bottom: 1px solid #ccc;
				font-size: 1rem;

				> li {
					float: none;
					margin: 0;
					padding: 0;
					border-top: 1px solid #ccc;

					&:before {
						content: none;
					}
				}

				a {
					position: relative;
					display: block;
					padding: 15px 25px;
					border-radius: 0;
					background: #fafafa;

					&:after {
						content: "";
						position: absolute;
						top: 0;
						bottom: 0;
						right: 20px;
						margin: auto;
						width: 6px;
						height: 8px;
						background: url(../img/icon_link.png) 0 0 no-repeat;
					}

					&.current {
						&:after {
							content: none;
						}
					}
				}
			}
		}

		.related-site {
			position: static;

			ul {
				float: none;
				margin: 0;
				font-size: 1rem;

				> li {
					float: none;
					margin: 0;
					padding: 0;

					&:before {
						content: none;
					}
				}

				a {
					display: block;
					padding: 15px 25px;
					text-align: left;
				}
			}
		}
	}
}

.site-submenu {
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	max-width: 980px;
	z-index: 100;
	pointer-events: none;

	&.fixed {
		position: fixed;
		top: 60px;
		animation: site-submenu-fixed .4s ease-in-out;
	}
	@keyframes site-submenu-fixed {
		0% {
			top: -110px;
		}
		100% {
			top: 78px;
		}
	}

	.site-submenu-container {
		float: right;
		position: relative;
		margin: 0;
		padding: 0;
		background: $color_bg;
		pointer-events: auto;

		.toggle {
			display: block;
			margin: 0 0 0 auto;
			padding: 0 12px 9px;
			cursor: pointer;
			text-decoration: none;

			span {
				display: none;
			}

			&:before {
				content: "";
				margin: 0 0 0 auto;
				display: block;
				width: 40px;
				height: 40px;
				background: url(../img/icon_menu_closed.png) 50% 50% no-repeat;
			}
		}

		ul {
			display: none;
			position: relative;
			margin: 0 20px 18px;
			padding: 14px 10px 0;
			list-style-type: none;
			font-weight: bold;
			letter-spacing: 0.05em;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 1px;
				background: #ccc;
			}

			> li {
				margin: 4px 0 0;
			}

			a {
				display: block;
			}
		}
	}

	&.open {
		.site-submenu-container {
			.toggle {
				&:before {
					background-image: url(../img/icon_menu_opened.png);
				}
			}

			ul {
				display: block;
			}
		}
	}

	@media (max-width: $breakpoint) {
		display: none;
	}
}

.breadcrumbs,
.contentpath {
	min-width: $maxwidth_content;
	@media (max-width: $breakpoint) {
		min-width: 0;
	}

	.breadcrumbs-container,
	.contentpath-container {
		margin: 0 auto 18px;
		width: $maxwidth_content;
		@media (max-width: $breakpoint) {
			width: auto;
		}
	}

	ol {
		margin: 0;
		padding: 0;
		list-style-type: none;
		font-size: 0.8125rem;
		line-height: 1.46153846154em;
		letter-spacing: 0.1em;
		color: $color_text_weak;

		@media (max-width: $breakpoint) {
			font-size: $fontsize_exsmall;
			line-height: 1em;
		}

		> li {
			display: inline-block;

			&:after {
				content: ">";
				margin: 0 0.5em;
				vertical-align: 1px;
			}

			&:last-child {
				color: $color_link;

				&:after {
					content: none;
				}
			}
		}

		a {
			color: inherit;
		}
	}
}

.breadcrumbs {
	@media (max-width: $breakpoint) {
		display: none;
	}
}

.contentpath {
	margin: 120px -20px 0;
	padding: 0 20px;
	background: #f5f5f5;

	.contentpath-container {
		margin-bottom: 0;
		padding: 26px 0;
		border-bottom: 1px solid #ccc;
	}

	@media (max-width: $breakpoint) {
		padding: 0;

		.contentpath-container {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

.site-body {
}

.site-footer {
	margin: 120px -20px 0;
	background: #f5f5f5;
	min-width: $maxwidth_content + 20px;
	@media (max-width: $breakpoint) {
		min-width: 0;
	}

	@media (max-width: $breakpoint) {
		padding: 0 5px;
	}

	.site-footer-container {
		@extend %clearfix;
		position: relative;
		margin: 0 auto;
		padding: 57px 10px 37px;
		width: $maxwidth_content;
		@media (max-width: $breakpoint) {
			width: auto;
		}

		&:before {
			content: "";
			position: absolute;
			left: 10px;
			right: 10px;
			bottom: 0;
			background: $color_ci;
			border-radius: 6px 6px 0 0;
			height: 7px;
		}

		@media (max-width: $breakpoint) {
			padding-top: 0;

			&:before {
				left: 0;
				right: 0;
				border-radius: 5px 5px 0 0;
				height: 5px;
			}
		}
	}

	.button {
		&:after {
			display: none;
		}
		&:hover {
			.text {
				opacity: .5;
			}
		}
	}

	.menu {
		letter-spacing: 0.05em;

		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}

		> ul {
			@extend %clearfix;

			> li {
				float: left;
				width: 25%;

				> ul {
					margin: 25px 0 0;
					padding: 20px 0 0;
					border-top: 1px dotted #999;

					&:first-child {
						margin-top: 0;
						padding-top: 0;
						border-top: 0 none;
					}

					> li {
						font-size: $fontsize_small;
						line-height: 1.42857142857em;

						> a {
							font-weight: 700;
						}

						> ul {
							margin: 24px 0 0;

							> li {
								margin: 6px 0 0;
								font-size: $fontsize_exsmall;
								line-height: 1.5em;

								> a {
									color: #808080;
								}
							}
						}
					}
				}
			}
		}

		@media (max-width: $breakpoint) {
			margin-left: -15px;
			margin-right: -15px;

			> ul {
				> li {
					float: none;
					width: auto;

					> ul {
						border-top: 0 none;

						> li {
							border-bottom: 1px solid #ccc;

							> a {
								position: relative;
								display: block;
								padding: 12px 20px 12px 15px;
								font-weight: normal;

								&:before {
									content: "";
									position: absolute;
									top: 0;
									bottom: 0;
									right: 15px;
									margin: auto;
									width: 5px;
									height: 8px;
									background: url(../img/icon_link.png) 50% 50% no-repeat;
								}
							}

							> ul {
								display: none;
							}
						}

						&.buttons {
							margin-top: 30px;
							margin-left: -10px;
							padding-top: 0;
							padding-left: 15px;
							padding-right: 15px;

							> li {
								float: left;
								box-sizing: border-box;
								border-bottom: 0 none;
								margin: 0;
								padding: 0 0 0 10px;
								width: 50%;

								> a {
									font-weight: 700;
								}
							}
						}
					}
				}
			}
		}
	}

	.buttons {
		@extend %clearfix;
		margin: 0;
		padding: 0;
		list-style-type: none;
		font-size: $fontsize_small;
		line-height: 1.42857142857em;
		font-weight: 700;

		> li {
			margin-top: 10px;
		}

		.button {
			position: relative;
			display: block;
			padding: 12px 20px;
			border-radius: 6px;
			background: $color_bg;
			color: $color_text;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				right: 15px;
				margin: auto;
				width: 5px;
				height: 8px;
				background: url(../img/icon_link.png) 50% 50% no-repeat;
			}
		}
	}

	.related-sites {
		@extend %clearfix;
		margin: 60px 0 0;
		padding: 60px 0 55px;
		border-top: 1px dotted #999;

		.buttons {
			float: left;
			width: 25%;

			> li {
				float: left;
				margin-top: 0;
				width: 100%;
			}

			.button {
				background: #999;
				color: #fff;

				&[target="_blank"] {
					&:before {
						right: 13px;
						width: 13px;
						height: 13px;
						background-image: url(../img/icon_external_white.png);
					}
				}
			}
		}

		.sns {
			float: right;
			margin: 0;
			padding: 0;
			list-style-type: none;

			> li {
				float: left;
				margin: 0 0 0 12px;

				&:first-child {
					margin-left: 0;
				}
			}

			a,
			img {
				display: block;
			}
		}

		@media (max-width: $breakpoint) {
			margin-top: 30px;
			padding-top: 30px;
			padding-bottom: 30px;

			.buttons {
				float: none;
				width: auto;
			}

			.sns {
				float: none;
				margin: 30px 0 0;
				width: auto;
				text-align: center;

				> li {
					float: none;
					display: inline-block;
					vertical-align: top;
				}
			}
		}
	}

	.utility {
		float: left;
		font-size: $fontsize_exsmall;
		line-height: 1.66666666667em;

		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;

			> li {
				float: left;
				position: relative;
				margin: 0 0 0 20px;
				padding: 0 0 0 21px;

				&:before {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					margin: auto;
					height: 1em;
					width: 1px;
					background: #d9d9d9;
				}

				&:first-child {
					margin-left: 0;
					padding-left: 0;

					&:before {
						content: none;
					}
				}
			}
		}

		@media (max-width: $breakpoint) {
			float: none;
			text-align: center;

			ul {
				> li {
					float: none;
					display: inline-block;
					margin: 0 0 0 15px;
					padding: 0 0 0 16px;

					&:before {
						background: #999;
					}

					&:first-child {
						margin-left: 0;
						padding-left: 0;
					}
				}
			}
		}
	}

	.copyright {
		float: right;
		margin: 0;
		font-size: .625rem;
		line-height: 1.2;
		text-align: right;

		small {
			font-size: 100%;
		}

		@media (max-width: $breakpoint) {
			float: none;
			margin: 25px 0 0;
			text-align: center;
		}
	}
}

.contentpath + .site-footer {
	margin-top: 0;
}


.page {
	> .header {
		position: relative;
		box-sizing: border-box;
		margin: 0 -20px;

		.title-plain {
			box-sizing: border-box;
			margin: 0 auto;
			padding: 32px 60px 100px;
			width: $maxwidth_content;
			@media (max-width: $breakpoint) {
				width: auto;
			}
			text-align: center;

			@media (max-width: $breakpoint) {
				padding-left: 15px;
				padding-right: 15px;
				padding-bottom: 0;
			}

			.category {
				margin: 0;
				padding: 0;
				color: #808080;
				line-height: 1.5;

				&:before,
				&:after {
					content: "-";
					margin: 0 0.25em;
				}
			}

			.title {
				margin: 5px 0;
				padding: 0;
				font-weight: 700;
				font-size: 2.25rem;
				line-height: 1.5;
				letter-spacing: 0.05em;
			}

			.pubdate {
				margin: 18px 0 0;
				padding: 0;
				text-align: right;
				font-weight: 700;
				letter-spacing: 0.025em;
			}
		}

		.title-cover {
			display: block;
			margin: 0;
			padding: 0;
			width: 100%;

			figcaption {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 60px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				@media (max-width: $breakpoint) {
					bottom: 6px;
				}
			}

			.cover-image {
				display: block;
				width: 100%;
			}

			.title {
				margin: 0;
				padding: 0;
				text-align: center;
				font-size: $fontsize_exlarge;
				line-height: 1.33333333333;
				letter-spacing: 0.05em;

				@media (max-width: $breakpoint) {
					font-size: 1.375rem;
				}
			}

			.subtitle {
				margin: 22px 0 0;
				padding: 0;
				text-align: center;
				font-size: 0.9375rem;
				line-height: 1.26666666667em;

				@media (max-width: $breakpoint) {
					margin-top: 8px;
					font-size: .5rem;
				}
			}
		}
	}


	> .body {
		position: relative;
		box-sizing: border-box;
		margin: -100px auto 0;
		padding: 50px 60px 0;
		border-radius: 6px 6px 0 0;
		width: $maxwidth_content;
		@media (max-width: $breakpoint) {
			width: auto;
		}
		min-height: 100px;
		background: $color_bg;

		@media (max-width: $breakpoint) {
			margin-top: 0;
			margin-left: -20px;
			margin-right: -20px;
			padding-top: 30px;
			padding-left: 15px;
			padding-right: 15px;
			border-radius: 0;
		}

		> :first-child {
			margin-top :0;

			> li:first-child {
				margin-top :0;
			}
		}
	}

	> .footer {
	}
}
