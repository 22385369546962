@charset 'utf-8';
@import '../_env.scss';

.page > .body > section:first-child {
	> .section-header {
		.h2,
		.h3,
		.h4 {
			margin-top: 0;
		}
	}
}

section {
	> .section-body {
		@extend %clearfix;

		> :first-child {
			margin-top: 0;

			> li:first-child {
				margin-top: 0;
			}
		}

		> section:first-child {
			> .section-header {
				.h3 {
					margin-top: 30px;
				}

				.h4,
				.meta {
					margin-top: 0;
				}
			}
		}
	}
}

.section-header {
	@extend %clearfix;
	display: flex;
	align-items: center;

	.h2,
	.h3,
	.h4 {
		flex: 1;
	}

	.meta {
		position: relative;
		margin: 36px 0 24px;
		@media (max-width: $breakpoint) {
			margin-top: 35px;
		}

		.link-list {
			@extend %clearfix;
			margin: 0 0 0 20px;
			padding: 0;
			border-left: 1px solid #d9d9d9;
			list-style-type: none;
			font-size: .875rem;
			line-height: 1.25;
			@media (max-width: $breakpoint) {
				font-size: .75rem;
			}

			> li {
				float: left;
				margin: 0 0 0 20px;
			}

			a {
				&:before {
					content: "";
					display: inline-block;
					margin: 0 8px 0 0;
					width: 6px;
					height: 8px;
					background: url(../img/icon_link.png) 0 0 no-repeat;
					vertical-align: 1px;
				}
			}
		}
	}
}

.h2 {
	margin: 100px 0 40px;
	padding: 0;
	font-weight: 700;
	font-size: 2rem;
	line-height: 1.5;
	letter-spacing: 0.05em;
	@media (max-width: $breakpoint) {
		font-size: 1.25rem;
	}

	> br {
		display: none;
	}

	.supertitle {
		display: block;
		font-weight: normal;
		font-size: $fontsize_exsmall;
		line-height: $lineheight_thin;
		color: #999;
		@media (max-width: $breakpoint) {
			font-size: .5rem;
			letter-spacing: 0;
		}

		&:before {
			content: "";
			display: inline-block;
			margin: 0 8px 0 0;
			border-radius: 4px;
			width: 24px;
			height: 8px;
			background: $color_ci;
			@media (max-width: $breakpoint) {
				margin-right: 5px;
				border-radius: 3px;
				width: 19px;
				height: 6px;
			}
		}
	}

	.title {
		display: block;
		margin: 18px 0 0;
		@media (max-width: $breakpoint) {
			margin-top: 3px;
		}
	}
}

.h3 {
	margin: 64px 0 24px;
	padding: 0;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 1.5;
	letter-spacing: 0.05em;
	@media (max-width: $breakpoint) {
		font-size: 1rem;
	}

	&:before {
		content: "";
		display: block;
		margin: 0 8px 0 0;
		border-radius: 2px;
		width: 16px;
		height: 4px;
		background: $color_ci;
	}

	.title {
		display: block;
		margin: 14px 0 0;
		@media (max-width: $breakpoint) {
			margin-top: 4px;
		}
	}
}

.h4 {
	margin: 40px 0 24px;
	padding: 0;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.5;
	letter-spacing: 0.05em;
	@media (max-width: $breakpoint) {
		margin-top: 30px;
		font-size: 1rem;
	}

	.title {
		display: block;
		margin: 0;
		padding: 0;
	}
}

