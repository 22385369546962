@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 100;
    src: url('../font/NotoSansCJKjp-Thin.eot');
    src: url('../font/NotoSansCJKjp-Thin.eot?#iefix') format('embedded-opentype'),
    url('../font/NotoSansCJKjp-Thin.woff2') format('woff2'),
    url('../font/NotoSansCJKjp-Thin.woff') format('woff');
}

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 200;
    src: url('../font/NotoSansCJKjp-Light.eot');
    src: url('../font/NotoSansCJKjp-Light.eot?#iefix') format('embedded-opentype'),
    url('../font/NotoSansCJKjp-Light.woff2') format('woff2'),
    url('../font/NotoSansCJKjp-Light.woff') format('woff');
}
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 300;
    src: url('../font/NotoSansCJKjp-DemiLight.eot');
    src: url('../font/NotoSansCJKjp-DemiLight.eot?#iefix') format('embedded-opentype'),
    url('../font/NotoSansCJKjp-DemiLight.woff2') format('woff2'),
    url('../font/NotoSansCJKjp-DemiLight.woff') format('woff');
}
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 400;
    src: url('../font/NotoSansCJKjp-Regular.eot');
    src: url('../font/NotoSansCJKjp-Regular.eot?#iefix') format('embedded-opentype'),
    url('../font/NotoSansCJKjp-Regular.woff2') format('woff2'),
    url('../font/NotoSansCJKjp-Regular.woff') format('woff');
}
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 500;
    src: url('../font/NotoSansCJKjp-Medium.eot');
    src: url('../font/NotoSansCJKjp-Medium.eot?#iefix') format('embedded-opentype'),
    url('../font/NotoSansCJKjp-Medium.woff2') format('woff2'),
    url('../font/NotoSansCJKjp-Medium.woff') format('woff');
}
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 700;
    src: url('../font/NotoSansCJKjp-Bold.eot');
    src: url('../font/NotoSansCJKjp-Bold.eot?#iefix') format('embedded-opentype'),
    url('../font/NotoSansCJKjp-Bold.woff2') format('woff2'),
    url('../font/NotoSansCJKjp-Bold.woff') format('woff');
}
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 900;
    src: url('../font/NotoSansCJKjp-Black.eot');
    src: url('../font/NotoSansCJKjp-Black.eot?#iefix') format('embedded-opentype'),
    url('../font/NotoSansCJKjp-Black.woff2') format('woff2'),
    url('../font/NotoSansCJKjp-Black.woff') format('woff');
}
