@charset 'utf-8';
@import '../_env.scss';

.list-normal {
	margin: 16px 0 0;
	padding: 0;
	list-style-type: none;
	font-size: .875rem;
	line-height: 2;

	> li {
		margin: 14px 0 0;
		padding: 0 0 0 16px;
		text-indent: -16px;

		&:first-child {
			margin-top: 0;
		}

		&:before {
			content: "";
			display: inline-block;
			margin: 0 12px 0 0;
			border-radius: 2px;
			width: 4px;
			height: 4px;
			background: #333;
			vertical-align: 4px;
		}

		> * {
			text-indent: 0;
		}
	}
}

.list-ordered {
	margin: 16px 0 0;
	padding: 0;
	border-bottom: 1px solid #ccc;
	list-style-type: none;
	line-height: 2;
	counter-reset: order;

	> li {
		margin: 0;
		padding: 12px 0 12px 2em;
		border-top: 1px solid #ccc;
		text-indent: -2em;
		counter-increment: order;

		> * {
			text-indent: 0;
		}

		&:before {
			content: counter(order) ".";
			display: inline-block;
			width: 2em;
			text-indent: 0;
		}
	}
}



.list-definition {
	margin: 0;
	padding: 0;

	dt {
		margin: 0;
		padding: 0;
		font-weight: 700;
	}

	dd {
		margin: 0;
		padding: 0;
	}
}



.list-columns {
	display: flex;
	flex-wrap: wrap;
	margin: 16px 0 0;
	padding: 0;
	list-style-type: none;
	line-height: 2;

	@media (max-width: $breakpoint) {
		display: block;
	}

	> li {
		margin: 0;
		width: 50%;

		@media (max-width: $breakpoint) {
			width: auto;
		}
	}
}



.list-plain {
	margin: 16px 0 0;
	padding: 0;
	list-style-type: none;
	line-height: 2;

	> li {
		margin: 0;
	}
}



.list-manually {
	margin: 16px 0 0;
	padding: 0;
	list-style-type: none;
	font-size: .875rem;
	line-height: 2;

	> li {
		margin: 14px 0 0;
		padding: 0 0 0 2.75em;
		text-indent: -2.75em;

		&:first-child {
			margin-top: 0;
		}

		> * {
			text-indent: 0;
		}
	}
}




