@charset 'utf-8';
@import '../_env.scss';

.pc {
	@media (max-width: $breakpoint) {
		display: none;
	}
}

.sp {
	@media (min-width: $breakpoint) {
		display: none;
	}
}

.note {
	margin: 0 0 -14px;
	padding: 0;
	font-size: 75%;
	text-align: right;
}

.center {
	text-align: center;
}

.right {
	text-align: right;
}


.news-category {
	margin: 0 0 10px;
	padding: 8px 0;
	display: inline-block;
	width: 150px;
	background: #e5e5e5;
	text-indent: 0;
	text-align: center;
	font-size: 0.75rem;
	font-weight: 700;
}
.table-news {
	.news-category {
		margin-left: 16px;

		@media (max-width: $breakpoint) {
			margin-left: 0;
		}
	}
}

.page {
	> .header {
		.news-category {
			display: block;
			margin: 10px 0 0 auto;
			line-height: 1.5;
		}
	}
}