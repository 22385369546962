@charset 'utf-8';
@import '../_env.scss';

.button-block {
	margin: 15px 0 0;

	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	button,
	.button {
		display: block;
		margin: 15px 0 0;
		width: 100%;
		max-width: 260px;

		@media (max-width: $breakpoint) {
			max-width: none;
		}
	}
}

.button-list,
.button-list-wide {
	display: flex;
	flex-wrap: wrap;
	margin: 15px -10px 0;
	padding: 0;
	list-style-type: none;

	> li {
		box-sizing: border-box;
		margin: 15px 0 0;
		width: 33.33%;
		width: calc(100% / 3);
		padding: 0 10px 0;

		&:first-child,
		&:first-child + li,
		&:first-child + li + li {
			margin-top: 0;
		}

		@media (max-width: $breakpoint) {
			width: 50%;

			&:first-child + li + li {
				margin-top: 20px;
			}
		}
	}

	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	button,
	.button {
		width: 100%;
	}
}

.button-list-wide {
	@media (max-width: $breakpoint) {
		display: block;
	}

	> li {
		width: 50%;

		&:first-child + li + li {
			margin-top: 15px;
		}

		@media (max-width: $breakpoint) {
			width: auto;

			&:first-child,
			&:first-child + li {
				margin-top: 15px;
			}
		}
	}
}


%button_input {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
	padding: 0;
	border: 0 none;
	background: transparent;
	display: inline;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	letter-spacing: inherit;
	text-align: left;
}

%button_link {
	text-decoration: none;
}

input[type="button"],
input[type="submit"],
input[type="reset"],
button {
	@extend %button_input;
}

a.button {
	@extend %button_link;
}

input[type="button"],
input[type="submit"],
input[type="reset"],
button,
.button {
	position: relative;
	z-index: 1;
	display: inline-block;
	box-sizing: border-box;
	padding: 14px 58px 14px 20px;
	border-radius: 6px;
	background: #cc0000;
	color: #fff;
	font-size: .875rem;
	line-height: 1.5;
	font-weight: 700;
	letter-spacing: 0.05em;
	cursor: pointer;
	opacity: 1;
	overflow: hidden;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 1;
		right: 20px;
		margin: auto;
		width: 18px;
		height: 18px;
		background: url(../img/icon_button.png) 0 0 no-repeat;
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: -100%;
		z-index: 0;
		width: 100%;
		height: 100%;
		background: #e54c59;
		transition: all .2s ease-in-out;
		opacity: 0;
	}

	.text {
		position: relative;
		z-index: 1;
	}

	&:hover {
		opacity: 1;

		&:after {
			left: 0%;
			opacity: 1;
		}
	}
}
