@charset 'utf-8';
@import '../_env.scss';

.block-attention {
	margin: 60px 0 16px;
	color: $color_attention;

	p {
		margin: 0;
		padding: 0;
		font-size: 1.125rem;
		line-height: 1.77777777778;
		letter-spacing: 0.05em;
		text-indent: -0.5em;

		@media (max-width: $breakpoint) {
			font-size: .9375rem;
			line-height: 1.6;
		}

		&:before {
			content: "「";
		}
	}

	.phrase {
		display: inline-block;
		text-indent: 0;

		&:last-child {
			&:after {
				content: "」";
			}
		}
	}
}

.block-attention-large {
	margin: 60px 0 30px;
	color: $color_attention;

	p {
		position: relative;
		display: table;
		margin: 0;
		padding: 0 36px;
		font-size: 1.375rem;
		line-height: 1.63636363636;
		letter-spacing: 0.05em;

		@media (max-width: $breakpoint) {
			padding: 0 20px;
			font-size: 1rem;
			line-height: 1.5;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			border-left: 1px solid;
			border-top: 1px solid;
			width: 12px;
			height: 24px;

			@media (max-width: $breakpoint) {
				height: 18px;
			}
		}

		&:after {
			content: "";
			position: absolute;
			right: 0;
			bottom: 0;
			border-right: 1px solid;
			border-bottom: 1px solid;
			width: 12px;
			height: 24px;

			@media (max-width: $breakpoint) {
				height: 18px;
			}
		}
	}

	.phrase {
		display: inline-block;
	}
}

.block-attention-exlarge {
	margin: 60px 0 30px;
	color: $color_attention;

	p {
		position: relative;
		box-sizing: border-box;
		margin: 0 auto;
		padding: 0 36px;
		max-width: 680px;
		font-size: 1.5rem;
		line-height: 1.66666666667;
		letter-spacing: 0.05em;
		text-align: center;

		@media (max-width: $breakpoint) {
			padding: 0 20px;
			font-size: 1.25rem;
			line-height: 1.5;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			border-left: 1px solid;
			border-top: 1px solid;
			width: 12px;
			height: 24px;

			@media (max-width: $breakpoint) {
				height: 18px;
			}
		}

		&:after {
			content: "";
			position: absolute;
			right: 0;
			bottom: 0;
			border-right: 1px solid;
			border-bottom: 1px solid;
			width: 12px;
			height: 24px;

			@media (max-width: $breakpoint) {
				height: 18px;
			}
		}
	}

	.phrase {
		display: inline-block;
	}
}



.block-image {
	margin: 56px 0 0;

	img {
		display: block;
		margin: auto;
		max-width: 100%;
		border-radius: 6px;
	}
}



.block-image-large {
	margin: 40px -60px 0;

	@media (max-width: $breakpoint) {
		margin-left: 0;
		margin-right: 0;
	}

	img {
		display: block;
		margin: auto;
		max-width: 100%;
		border-radius: 6px;
	}
}


.section-body {
	.block-image {
		margin: 2em 0 0;
	}
}

.block-columns-ltr,
.block-columns-rtl {
	display: flex;
	margin: 30px 0 0;

	@media (max-width: $breakpoint) {
		display: block;
	}

	> .col {
		position: relative;

		@media (max-width: $breakpoint) {
			> :first-child {
				margin-top: 10px;
			}

			> section:first-child > .section-header {
				> .h2,
				> .h3,
				> .h4 {
					margin-top: 10px;
				}
			}
		}

		.button-list {
			> li {
				width: 260px;
			}
		}

		.col-footer {
			@extend %clearfix;
			display: flex;
			width: 100%;
			align-items: flex-end;

			@media (max-width: $breakpoint) {
				position: static;
			}

			.link-list,
			.link-list-strong {
				float: left;
				flex: 1;
			}

			.banner {
				float: right;
				margin: 40px 0 -6px;
				flex: 1;

				@media (max-width: $breakpoint) {
					margin-top: 35px;
				}

				img {
					display: block;
					max-width: 100%;
				}
			}
		}
	}
}


.block-columns-ltr {
	> .col {
		&:first-child {
			margin: 0 0 0 -60px;
			width: 48.780487804%;

			@media (max-width: $breakpoint) {
				margin: 0;
				width: auto;
			}
		}

		&:last-child {
			flex: 1;
			margin: 0 0 0 40px;

			@media (max-width: $breakpoint) {
				margin: 0;
			}
		}
	}
}



.block-columns-rtl {
	> .col {
		&:first-child {
			order: 2;
			margin: 0 -60px 0 0;
			width: 48.780487804%;

			@media (max-width: $breakpoint) {
				margin: 0;
				width: auto;
			}
		}

		&:last-child {
			order: 1;
			flex: 1;
			margin: 0 40px 0 0;

			@media (max-width: $breakpoint) {
				margin: 0;
			}
		}
	}
}



.block-bg,
.block-bg-silver,
.block-bg-gray,
.block-bg-none {
	margin: 40px calc(-1 * (100vw - #{$maxwidth_content} + 120px) / 2) 0;
	padding: 30px 0;
	width: 100vw;
	background: #fafafa;

	@media (max-width: $maxwidth_content) {
		margin-left: -70px;
		margin-right: -70px;
		width: $maxwidth_content + 20px;
	}

	@media (max-width: $breakpoint) {
		margin-left: -15px;
		margin-right: -15px;
		width: auto;
	}

	& + & {
		margin-top: 2px;
	}

	.block-bg-body {
		box-sizing: border-box;
		margin: 0 auto;
		padding: 0 60px;
		max-width: $maxwidth_content;

		@media (max-width: $breakpoint) {
			padding: 0 15px;
		}

		> :first-child,
		> section:first-child > .section-header > .h2,
		> section:first-child > .section-header > .h3,
		> section:first-child > .section-header > .h4 {
			margin-top: 0;
		}

		> .block-columns-ltr,
		> .block-columns-rtl {
			> .col {
				> :first-child {
					margin-top: 0;
				}

				> section:first-child > header {
					.h2,
					.h3,
					.h4 {
						margin-top: 0;

						@media (max-width: $breakpoint) {
							margin-top: 30px;
						}
					}
				}
			}
		}
	}
}
.block-bg-silver {
	background: #f2f2f2;
}
.block-bg-gray {
	background: #b3b3b3;
	color: #fff;
}
.block-bg-none {
	background: transparent;
}


.block-bg-area {
	margin: 40px 0 0;
	padding: 25px 30px;
	background: #fafafa;

	> :first-child {
		margin-top: 0;

		> dt:first-child,
		> li:first-child {
			margin-top: 0;
		}
	}
}
