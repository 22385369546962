@charset 'utf-8';
@import '../_env.scss';

.table-data {
	margin: 40px 0 0;
	border-bottom: 1px solid #ccc;
	line-height: 1.75;

	th,
	td {
		padding: 15px 20px 15px 0;
		border-top: 1px solid #ccc;
		text-align: left;
		vertical-align: top;

		&:last-child {
			padding-right: 0;
		}
	}

	th {
		font-weight: 700;
	}

	.row-sub {
		th,
		td {
			border-top-style: dotted;
		}
	}
}



.table-news {
	margin: 40px 0 0;
	line-height: 1.5;

	th,
	td {
		padding: 20px 20px 20px 0;
		border-bottom: 1px solid #ccc;
		text-align: left;
		vertical-align: top;

		&:last-child {
			padding-right: 0;
		}

		@media (max-width: $breakpoint) {
			display: block;
			padding-right: 0;
		}
	}

	th {
		white-space: nowrap;
		font-weight: 700;

		@media (max-width: $breakpoint) {
			padding-bottom: 0;
			border-bottom: 0 none;
		}
	}

	td {
		padding-left: 16px;
		text-indent: -16px;

		@media (max-width: $breakpoint) {
			padding-top: 5px;
			padding-left: 0;
			text-indent: 0;
		}
	}

	a {
		&:before {
			content: "";
			display: inline-block;
			margin: 0 10px 0 0;
			width: 6px;
			height: 8px;
			background: url(../img/icon_link.png) 0 0 no-repeat;
			vertical-align: 2px;

			@media (max-width: $breakpoint) {
				margin-right: 8px;
				vertical-align: 1px;
			}
		}

		&[target]:after {
			content: "";
			display: inline-block;
			margin: 0 0 0 10px;
			width: 13px;
			height: 13px;
			background: url(../img/icon_external.png) 0 0 no-repeat;
			vertical-align: -1px;

			@media (max-width: $breakpoint) {
				margin-left: 5px;
				vertical-align: -2px;
			}
		}
 	}
}
